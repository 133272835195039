import React, { useState } from "react";
import { Link } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { StaticImage } from "gatsby-plugin-image";

//import { list } from "../utils/cartUtil";

const MenuItem = ({ label, to, check }) => {
  return (
    <Link
      to={to}
      onClick={check}
      className="text-sm tracking-xl text-center text-white md:text-black hover:text-black md:hover:text-mustard-yellow focus:text-mustard-yellow font-sans-serif uppercase"
    >
      {label}
    </Link>
  );
};

export default function NavBar() {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked((clicked) => !clicked);
  };

  const Checked = () => {
    setClicked((clicked) => !clicked);
  };
  return (
    <div className="flex flex-row space-x-4 font-serif bg-white z-50 text-gray-600 justify-between items-center px-4 md:px-16 py-2 shadow sticky top-0">
      <div>
        <Link to="/">
          <StaticImage
            src="../../static/images/website/logos.png"
            alt="logo"
            width={80}
          />
        </Link>
      </div>
      <div className="hidden md:flex flex-row space-x-4">
        <MenuItem label="I'm a Buyer" to="/buyer" />
        <MenuItem label={<FontAwesomeIcon icon={faSearch} />} to="/search" />
        <MenuItem label="I'm a Seller" to="/seller" />
      </div>
      <div className="flex text-xs font-sans-serif flex-row space-x-4 justify-end">
        <button className="uppercase py-1 px-8 border tracking-xl outline-none focus:outline-none hidden">
          Login
        </button>
      </div>
      <div className="block md:hidden">
        <button
          className={
            clicked
              ? "outline-none focus:outline-none p-3  text-mustard-yellow"
              : "outline-none focus:outline-none p-3"
          }
          onClick={handleClick}
        >
          <svg
            className=" fill-current"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
          >
            <path
              d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
              fill="#1040e2"
            />
            <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" />
          </svg>
        </button>
        <ul
          className={
            clicked
              ? "block absolute text-center visible transition shadow-xl ease-in-out duration-100 transform -translate-y-0 z-50 w-full left-0 mt-6 bg-dusty-pink py-4 px-6"
              : " invisible absolute text-center w-full left-0 mt-6  transition ease-in-out duration-100 transform -translate-y-4"
          }
        >
          <li className="py-1">
            <MenuItem
              check={Checked}
              label={<FontAwesomeIcon icon={faSearch} />}
              to="/search"
            />
          </li>
          <li className="py-1">
            <MenuItem check={Checked} label="I'm a Buyer" to="/buyer" />
          </li>
          <li className="py-1">
            <MenuItem check={Checked} label="I'm a Seller" to="/seller" />
          </li>
        </ul>
      </div>
      {/* <div>{JSON.stringify(list())}</div> */}
    </div>
  );
}
